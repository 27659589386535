import React from "react";
import DefaultRentalPage from "../shopcrate-framework/page-content/product/DefaultRentalPage";
import Page404 from "./Page404";
import Helmet from "../components/Helmet";

function Rental() {
    return (
        <DefaultRentalPage
            error404Page={ <Page404/> }
            helmet={ (productContext) => (
                <Helmet title={ productContext.product ? productContext.product.name : null }/>
            )}
        />
    );
}

export default React.memo(Rental);
