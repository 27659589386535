import React from "react";
import "react-dates/initialize";
import {
    I18nextProvider
} from "react-i18next";

import {
    CartManager
} from "./internal/CartManager";
import {
    OrderManager
} from "./internal/OrderManager";
import {
    RentalManager
} from "./internal/RentalManager";
import {
    PaymentMethodManager
} from "./internal/PaymentMethodManager";
import ShopCrateLocalization from "../ShopCrateLocalization";

function ShopCrateManager({ children }) {
    return (
        <I18nextProvider i18n={ ShopCrateLocalization }>
            <CartManager>
                <OrderManager>
                	<RentalManager>
                    	<PaymentMethodManager>
                       		{ children }
                    	</PaymentMethodManager>
                	</RentalManager>
                </OrderManager>
            </CartManager>
        </I18nextProvider>
    )
}

export default ShopCrateManager;
