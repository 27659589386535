import React, {
    useEffect,
    useContext,
    useState
} from "react";
import {
    useHistory,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";

import shopcrateApi from "../../ShopCrateAPI";
import CartContext from "../../context/internal/CartManager";
import PaymentMethodContext from "../../context/internal/PaymentMethodManager";
import Loading from "../../components/Loading";
import DefaultOrderSuccessPage from "./DefaultOrderSuccessPage";

function OrderStatusPageContent(props) {
    const cart = useContext(CartContext);
    const paymentMethod = useContext(PaymentMethodContext);
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState(null);
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);

    const getOrderStatus = () => {
        let orderToken = props.match.params.orderToken;
        setLoading(true);
        setError(null);
        shopcrateApi.post("/getOrderStatus", { orderToken: orderToken })
            .then((response) => {
                if(response.data.valid) {
                    const status = response.data.status;
                    setStatus(status);
                    if(status === null || status === "open" || status === "pending") {
                        setTimeout(() => {
                            getOrderStatus();
                        }, 1000);
                    } else if(status === "paid") {
                        cart.clearCart();
                        paymentMethod.setPaymentMethod(null);
                        setLoading(false);
                        getOrder();
                    } else {
                        history.push("/order/overview");
                        setLoading(false);
                    }
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch(() => {
                setError(i18next.t("errorGeneral"));
                setLoading(false);
            });
    }

    const getOrder = () => {
        let orderToken = props.match.params.orderToken;
        shopcrateApi.post("/getOrder", { orderToken: orderToken })
            .then((response) => {
                if(response.data.valid) {
                    setOrder(response.data.order);
                } else {
                    setError("Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch(() => {
                setError("Er ging iets fout bij het ophalen van de data.");
                setLoading(false);
            });
    }

    useEffect(() => {
        getOrderStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { status === "paid" ? (
                <DefaultOrderSuccessPage order={ order }/>
            ) : loading ? (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4><Trans i18nKey="orderStatusLoading"/></h4>
                        <Loading/>
                    </div>
                </div>
            ) : (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4>Klik op de knop hieronder om de status op te halen...</h4>
                        <button className="btn btn-success btn-lg" onClick={ getOrderStatus }>
                            Refresh
                        </button>
                    </div>
                </div>
            )}

        </React.Fragment>
    );
}

export default React.memo(withRouter(OrderStatusPageContent));
