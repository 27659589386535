import React from "react";
import {
    Link
} from "react-router-dom";
import ProductImage from "../ProductImage";

function ProductCard({ product, size = "small", noLink = false, noPrice = false }) {
    const url = "/" + (product.rental ? "rental" : "product") + "/" + product.id + "/" + product.url
    if(size === "small") {
        const image = product.images.length === 0 ? null : product.images[0];
        const linkContent = (
            <React.Fragment>
                <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
                    <ProductImage
                        image={ image }
                        size="175x130"
                        className="img-fluid rounded"
                        alt="Product"
                        style={{ objectFit: "contain" }}
                    />
                </div>
                <h6 className="mt-2">{ product.name }</h6>
            </React.Fragment>
        )
        return (
            <div>
                { noLink ? linkContent : (
                    <Link to={ url }>{ linkContent }</Link>
                )}
                { !noPrice && (
                    <p className="mb-0">
                        <b>&euro; { product.price.toFixed(2).replace(".", ",") }</b>
                    </p>
                )}
                <p className="text-success">
                    <i className="fas fa-check-circle mr-2"/>
                    <b>Morgen in huis</b>
                </p>
            </div>
        )
    } else if(size === "large") {
        const image = product.images.length === 0 ? null : product.images[0];
        const linkContent = (
            <React.Fragment>
                <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "250px", backgroundColor: "white" }}>
                    <img
                        src={ image ? image.url : "https://via.placeholder.com/350x250" }
                        className="img-fluid rounded"
                        alt="Product"
                        style={{ objectFit: "contain" }}
                    />
                </div>
                <h6 className="mt-2">{ product.name }</h6>
            </React.Fragment>
        )
        return (
            <div className="text-center">
                { noLink ? linkContent : (
                    <Link to={ url }>{ linkContent }</Link>
                )}
                { !noPrice && (
                    <p className="mb-0">
                        <b>&euro; { product.price.toFixed(2).replace(".", ",") }</b>
                    </p>
                )}
            </div>
        )
    }
}

export default ProductCard;
