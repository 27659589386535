import React from "react";

import Helmet from "../../components/Helmet";
import PaymentMethodSelectionPageContent from "../../shopcrate-framework/page-content/PaymentMethodSelectionPageContent";

function PaymentMethodSelection({ type = "buy" }) {
    return (
        <React.Fragment>
            <Helmet title="Betaalmethode"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Betaalmethode</h1>
                </div>
            </div>
            <div className="container">
                <PaymentMethodSelectionPageContent type={ type }/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(PaymentMethodSelection);
