import React, {
    useContext,
    useEffect,
    useState
} from "react";
import moment from "moment";
import {
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import shopcrateApi from "../../ShopCrateAPI";
import RentalContext from "../../context/internal/RentalManager";
import PaymentMethodContext from "../../context/internal/PaymentMethodManager";
import Loading from "../../components/Loading";
import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import ProductCard from "../../components/product/ProductCard";
import priceFormatter from "../../components/priceFormatter";

function RentalOrderOverviewPageContent({ match }) {
    const rental = useContext(RentalContext);
    const paymentMethodContext = useContext(PaymentMethodContext);

    const productId = match.params.productId;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const placeRentalOrder = () => {
        setLoading(true);
        setError(null);
        shopcrateApi.post("/placeRentalOrder", {
            rentalInfo: JSON.stringify(rental.rentalInfo),
            paymentMethod: paymentMethodContext.paymentMethod,
            productId
        })
            .then((response) => {
                if(response.data.valid) {
                    window.location = response.data.checkoutUrl;
                } else {
                    setError(i18next.t("errorGeneral") + " (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch(() => {
                setError(i18next.t("errorGeneral"));
                setLoading(false);
            });
    }

    useEffect(() => {
        rental.setCurrentProduct(productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    useEffect(() => {
        if(paymentMethodContext.paymentMethods === null) {
            paymentMethodContext.getPaymentMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(!rental.isRentalDateRangeValid()) {
        return (
            <Redirect to={ "/rental-order/" + productId + "/date" }/>
        );
    }
    if(!rental.isRentalInfoValid()) {
        return (
            <Redirect to={ "/rental-order/" + productId + "/info" }/>
        );
    }
    if(paymentMethodContext.paymentMethods && !paymentMethodContext.isPaymentMethodValid()) {
        return (
            <Redirect to={ "/rental-order/" + productId + "/paymentmethod" }/>
        );
    }

    const paymentMethod = !paymentMethodContext.paymentMethods ? null : paymentMethodContext.paymentMethods.find((paymentMethod) => {
        return paymentMethod.id === paymentMethodContext.paymentMethod;
    });

    return (
        <React.Fragment>
            <div className="mt-2 mb-4">
                <SteppedProgressBar>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="product"/> } to={ "/rental/" + productId } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="date"/> } to={ "/rental-order/" + productId + "/date" } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to={ "/rental-order/" + productId + "/info" } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to={ "/rental-order/" + productId + "/paymentmethod" } complete/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to={ "/rental-order/" + productId + "/overview" } active/>
                    <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                </SteppedProgressBar>
            </div>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { paymentMethodContext.paymentMethodsError ? (
                <Alert variant="danger">{ paymentMethodContext.paymentMethodsError }</Alert>
            ) : !rental.product || !paymentMethodContext.paymentMethods ? (
                <Loading/>
            ) : loading ? (
                <div className="card mb-3">
                    <div className="card-body text-center">
                        <h4>
                            <Trans i18nKey="redirectPayment"/>
                        </h4>
                        <Loading/>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="contactInformation"/>
                                        <Link to={ "/rental-order/" + productId + "/info" } className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { rental.rentalInfo.email }
                                        <br/>
                                        { rental.rentalInfo.phoneNumber }
                                    </p>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="invoiceAddress"/>
                                        <Link to={ "/rental-order/" + productId + "/info" } className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <p className="card-text">
                                        { rental.rentalInfo.invoiceFirstName + " " + rental.rentalInfo.invoiceLastName }
                                        <br/>
                                        { rental.rentalInfo.invoiceStreet + " " + rental.rentalInfo.invoiceHouseNumber }
                                        <br/>
                                        { rental.rentalInfo.invoicePostalCode + ", " + rental.rentalInfo.invoiceCity }
                                    </p>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="paymentMethod"/>
                                        <Link to={ "/rental-order/" + productId + "/paymentmethod" } className="btn btn-link btn-sm">
                                            <Trans i18nKey="edit"/>
                                        </Link>
                                    </h5>
                                    <div className="d-flex">
                                        <img src={ paymentMethod.image.svg } height="24px" alt="Icon"/>
                                        <span className="d-inline ml-2">
                                            { paymentMethod.description }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="product"/>
                                    </h5>
                                    <ProductCard product={ rental.product } size="large" noPrice noLink/>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">
                                        <Trans i18nKey="rent"/>
                                    </h5>
                                    { rental.rentalInfo.startDate && rental.rentalInfo.endDate && (
                                        <React.Fragment>
                                            <p className="card-text">
                                                { rental.getAmountOfDaysSelected() === 1 ? (
                                                    <React.Fragment>
                                                        { i18next.t("rentalDateSelected").replace("{date}", rental.rentalInfo.startDate.format("dddd LL")) }
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        { i18next.t("rentalDatesSelected")
                                                            .replace("{startDate}", moment(rental.rentalInfo.startDate).format("dddd LL"))
                                                            .replace("{endDate}", moment(rental.rentalInfo.endDate).format("dddd LL")) }
                                                    </React.Fragment>
                                                )}
                                            </p>
                                            <p className="card-text">
                                                <b>
                                                    { rental.getAmountOfDaysSelected() }{" "}
                                                    { i18next.t(rental.getAmountOfDaysSelected() === 1 ? "day" : "days").toLowerCase() }:{" "}
                                                    { priceFormatter(rental.price) }
                                                </b>
                                            </p>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <Link to={ "/rental-order/" + productId + "/paymentmethod" } className="btn btn-link">
                                <i className="fas fa-chevron-left mr-2"/>
                                <Trans i18nKey="previousStep"/>
                            </Link>
                            <div className="float-right">
                                <button className="btn btn-success" onClick={ placeRentalOrder }>
                                    <Trans i18nKey="pay"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default withRouter(React.memo(RentalOrderOverviewPageContent));
