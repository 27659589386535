import React from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import Navbar from "./components/Navbar";
import ScrollToTop from "./hooks/ScrollToTop";
import Footer from "./components/Footer";

import Home from "./pages/Home";
import Product from "./pages/Product";
import Category from "./pages/Category";
import Page404 from "./pages/Page404";
import Privacyverklaring from "./pages/Privacyverklaring";
import AlgemeneVoorwaarden from "./pages/AlgemeneVoorwaarden";

import Cart from "./pages/order/Cart";
import DeliveryInfo from "./pages/order/DeliveryInfo";
import PaymentMethodSelection from "./pages/order/PaymentMethodSelection";
import OrderOverview from "./pages/order/OrderOverview";

import OrderStatus from "./pages/order/OrderStatus";
import Rental from "./pages/Rental";
import RentalDate from "./pages/order/RentalDate";
import RentalInfo from "./pages/order/RentalInfo";
import RentalOrderOverview from "./pages/order/RentalOrderOverview";
import RentalOrderStatus from "./pages/order/RentalOrderStatus";

function ShopRouter() {
    return (
        <React.Fragment>
            <ScrollToTop/>
            <div className="site-content">
                <Navbar/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/privacyverklaring" exact component={Privacyverklaring}/>
                    <Route path="/algemene-voorwaarden" exact component={AlgemeneVoorwaarden}/>

                    <Route path={[
                        "/product/:productId",
                        "/product/:productId/:productUrl"
                    ]} exact component={Product}/>
                    <Route path={[
                        "/rental/:productId",
                        "/rental/:productId/:productUrl"
                    ]} exact component={Rental}/>
                    <Route path={[
                        "/category/:categoryId",
                        "/category/:categoryId/:categoryUrl"
                    ]} exact component={Category}/>
                    <Route path="/cart" exact component={Cart}/>
                    <Route path="/order/data" exact component={DeliveryInfo}/>
                    <Route path="/order/paymentmethod" exact component={PaymentMethodSelection}/>
                    <Route path="/order/overview" exact component={OrderOverview}/>
                    <Route path="/order/:orderToken" exact component={OrderStatus}/>
                    <Route path="/rental-order/:productId/date" exact component={RentalDate}/>
                    <Route path="/rental-order/:productId/info" exact component={RentalInfo}/>
                    <Route path="/rental-order/:productId/paymentmethod" exact>
                        <PaymentMethodSelection type="rental"/>
                    </Route>
                    <Route path="/rental-order/:productId/overview" exact component={RentalOrderOverview}/>
                    <Route path="/rental-order/complete/:orderToken" exact component={RentalOrderStatus}/>

                    <Route path={["/index", "/index.html", "/index.htm", "/index.php"]}>
                        <Redirect to="/"/>
                    </Route>
                    <Route path="/" component={Page404}/>
                </Switch>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

export default ShopRouter;
