import React, {
    useState
} from 'react';
import {
    Link,
    useRouteMatch
} from "react-router-dom";
import {
    Container,
    Nav,
    Navbar as RBNavbar
} from "react-bootstrap";

import logo from "../img/driverent-logo.svg";

function NavbarLink({to, title, exact = false, hidden = false, onClick}) {
    let match = useRouteMatch({
        path: to,
        exact: exact
    });
    let className = "nav-item" + (match ? " active" : "") + (hidden ? " d-none" : "");
    return (
        <li className={ className } data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to={ to } className="nav-link" onClick={ onClick }>
                { title }
            </Link>
        </li>
    );
}

function Navbar() {
    let [showCollapsed, setShowCollapsed] = useState(false);
    let collapse = () => {
        setShowCollapsed(false);
    }

    return (
        <React.Fragment>
            <RBNavbar bg="light" variant="light" expand="lg" className="fixed-top" expanded={ showCollapsed }>
                <Container>
                    <a className="navbar-brand" href="/">
                        <img src={ logo } alt="DriveRent" height="36px"/>
                    </a>
                    <RBNavbar.Toggle aria-controls="basic-navbar-nav" onClick={ () => setShowCollapsed(!showCollapsed) } />
                    <RBNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"/>
                        <Nav>
                            <NavbarLink to="/" title="Home" exact onClick={ collapse }/>
                            <NavbarLink to="/rental/8" title="Wielerbus" onClick={ collapse }/>
                        </Nav>
                    </RBNavbar.Collapse>
                </Container>
            </RBNavbar>

            <div style={{ height: "62px" }}/>
        </React.Fragment>
    );
}

export default React.memo(Navbar);
