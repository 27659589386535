import React from "react";

import Helmet from "../../components/Helmet";
import RentalOrderStatusPageContent from "../../shopcrate-framework/page-content/rental/RentalOrderStatusPageContent";

function RentalOrderStatus() {
    return (
        <React.Fragment>
            <Helmet title="Huurbevestiging"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Huurbevestiging</h1>
                </div>
            </div>
            <div className="container">
                <RentalOrderStatusPageContent/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(RentalOrderStatus);
