import React from "react";
import Helmet from "../components/Helmet";
import ProductCollectionCarousel from "../shopcrate-framework/components/collection/ProductCollectionCarousel";

import driveRentLogo from "../img/driverent-logo-white.svg";
import beukersLogo from "../img/logo/beukers-logo.svg";
import smithSolarLogo from "../img/logo/smithsolar-logo.svg";

function Home() {
    return (
        <React.Fragment>
            <Helmet/>
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>
                        <img
                            src={ driveRentLogo }
                            className="img-fluid"
                            alt="DriveRent"
                            style={{
                                maxHeight: "95px"
                            }}
                        />
                    </h1>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 pt-4">
                        <h1 style={{ fontSize: "4rem", color: "#001B4B" }}>
                            Fietsbus
                            <br/>
                            huren
                        </h1>
                    </div>
                    <div className="col-md-6">
                        <ProductCollectionCarousel collection="home"/>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "#f2fafc"}}>
                <div className="container pt-5 pb-5">
                    <div className="text-center pb-4">
                        <h1>Powered by</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center mb-3">
                            <a href="https://beukerstoyota.nl" target="_blank" rel="noopener noreferrer">
                                <img src={ beukersLogo } alt="Beukers Autobedrijf" style={{ maxHeight: "100px" }}/>
                            </a>
                        </div>
                        <div className="col-md-6 text-center mb-3">
                            <a href="https://smithsolar.nl" target="_blank" rel="noopener noreferrer">
                                <img src={ smithSolarLogo } alt="Smith Solar B.V." style={{ maxHeight: "100px" }}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="card">
                            <div className="card-body">
                                <p className="card-text"><b>B&S Logistiek</b></p>
                                <p className="card-text">
                                    Monsterseweg 121
                                    <br/>
                                    2691 JE 's-Gravenzande
                                </p>
                                <p className="card-text">
                                    <a href="dial:+31620967847">06 209 678 47</a> / <a href="dial:+31653376147">06 533 761 47</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <iframe id="map" title="map" width="100%" height="400" className="card"
                                src="https://maps.google.com/maps?q=Monsterseweg+121+2691+JE+s+Gravenzande&z=14&output=embed" allowFullScreen/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(Home);
