import React from "react";
import moment from "moment";
import shopcrateApi from "../../ShopCrateAPI";

const RentalContext = React.createContext(null);

export class RentalManager extends React.Component {
    getDefaultRentalInfo() {
        return {
            company: false,
            companyName: "",

            invoiceFirstName: "",
            invoiceLastName: "",
            invoiceStreet: "",
            invoiceHouseNumber: "",
            invoicePostalCode: "",
            invoiceCity: "",

            email: "",
            phoneNumber: "",

            startDate: "",
            endDate: ""
        };
    }

    constructor(props) {
        super(props);
        const rentalInfo = localStorage.rentalInfo !== undefined ? JSON.parse(localStorage.rentalInfo) : this.getDefaultRentalInfo();
        this.state = {
            rentalContext: {
                rentalInfo,

                currentProductId: null,
                product: null,
                availableDates: null,
                price: null,

                error: null,
                errorCode: null,
                priceError: null,

                setCurrentProduct: this.setCurrentProduct.bind(this),
                updateRentalInfo: this.updateRentalInfo.bind(this),
                isRentalInfoValid: this.isRentalInfoValid.bind(this),
                isRentalDateRangeValid: this.isRentalDateRangeValid.bind(this),
                isDateRangeAvailable: this.isDateRangeAvailable.bind(this),
                isDayAvailable: this.isDayAvailable.bind(this),
                getAmountOfDaysSelected: this.getAmountOfDaysSelected.bind(this)
            }
        }
    }

    componentDidMount() {
        const startDate = this.state.rentalContext.rentalInfo.startDate;
        const endDate = this.state.rentalContext.rentalInfo.endDate;
        if(this.isRentalDateRangeValid()) {
            this.getPriceForSelectedDates(startDate, endDate);
        } else {
            this.updateRentalInfo({ startDate: "", endDate: "" });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentProductId = this.state.rentalContext.currentProductId;
        const prevCurrentProductId = prevState.rentalContext.currentProductId;

        const startDate = this.state.rentalContext.rentalInfo.startDate;
        const endDate = this.state.rentalContext.rentalInfo.endDate;
        const prevEndDate = prevState.rentalContext.rentalInfo.endDate;

        if((currentProductId !== prevCurrentProductId || endDate !== prevEndDate) && currentProductId !== null && startDate !== null && endDate !== null) {
            this.getPriceForSelectedDates(startDate, endDate);
        }
        if(currentProductId !== prevCurrentProductId && currentProductId !== null) {
            this.getProduct();
            this.getAvailableRentalDates();
        }
    }

    updateRentalContext(state) {
        this.setState(previousState => {
            return { rentalContext: { ...previousState.rentalContext, ...state } }
        });
    }

    updateRentalInfo(updatedField) {
        this.setState(previousState => {
            const rentalInfo = { ...previousState.rentalContext.rentalInfo, ...updatedField }
            localStorage.rentalInfo = JSON.stringify(rentalInfo);
            return { rentalContext: { ...previousState.rentalContext, rentalInfo } }
        });
    }

    setCurrentProduct(productId) {
        this.updateRentalContext({ currentProductId: productId });
    }

    getProduct() {
        const currentProductId = this.state.rentalContext.currentProductId;
        this.updateRentalContext({ product: null, error: null });
        shopcrateApi.post("/getProduct", { productId: currentProductId })
            .then((response) => {
                if(response.data.valid) {
                    const product = response.data.product;
                    if(!product.rental) {
                        this.updateRentalContext({ error: "Er ging iets fout bij het ophalen van de data. (DOESNT_EXIST)", errorCode: "DOESNT_EXIST" });
                    } else {
                        this.updateRentalContext({ product });
                    }
                } else {
                    this.updateRentalContext({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.updateRentalContext({ error: "Er ging iets fout bij het ophalen van de data." });
            });
    }

    getAvailableRentalDates() {
        const currentProductId = this.state.rentalContext.currentProductId;
        this.updateRentalContext({ availableDates: null, error: null });
        shopcrateApi.post("/getProductAvailableRentalDates", {
            productId: currentProductId,
            startDate: moment().startOf("month").format("YYYY-MM-DD"),
            endDate: moment().add(6, "months").format("YYYY-MM-DD")
        })
            .then((response) => {
                if(response.data.valid) {
                    this.updateRentalContext({ availableDates: response.data.availableDates });
                    if(this.state.startDate && this.state.endDate) {
                        if(!this.isDateRangeAvailable(this.state.startDate, this.state.endDate)) {
                            this.updateRentalInfo({ startDate: null, endDate: null });
                        }
                    }
                } else {
                    this.updateRentalContext({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.updateRentalContext({ error: "Er ging iets fout bij het ophalen van de data." });
            })
    }

    getPriceForSelectedDates(startDate, endDate) {
        const currentProductId = this.state.rentalContext.currentProductId;
        if(!currentProductId || !startDate || !endDate || startDate > endDate) {
            this.updateRentalContext({ price: null });
            return;
        }
        this.updateRentalContext({ price: null, priceError: null });
        shopcrateApi.post("/getProductRentalPrice", {
            productId: currentProductId,
            startDate,
            endDate
        })
            .then((response) => {
                if(startDate !== this.state.rentalContext.rentalInfo.startDate || endDate !== this.state.rentalContext.rentalInfo.endDate) {
                    return;
                }
                if(response.data.valid) {
                    this.updateRentalContext({ price: response.data.price });
                } else {
                    this.updateRentalContext({ price: null, priceError: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.updateRentalContext({ price: null, priceError: "Er ging iets fout bij het ophalen van de data." });
            });
    }

    isRentalInfoValid() {
        const state = this.state.rentalContext.rentalInfo;

        if(state.company && state.companyName.length === 0) return false;

        if(state.invoiceFirstName.length === 0) return false;
        if(state.invoiceLastName.length === 0) return false;
        if(state.invoiceStreet.length === 0) return false;
        if(state.invoiceHouseNumber.length === 0) return false;
        if(state.invoicePostalCode.length === 0) return false;
        if(state.invoiceCity.length === 0) return false;

        if(state.email.length === 0) return false;
        if(state.phoneNumber.length === 0) return false;

        return true;
    }

    isRentalDateRangeValid() {
        const state = this.state.rentalContext.rentalInfo;

        if(!state.startDate || !state.endDate) return false;

        if(state.startDate.length === 0) return false;
        if(state.endDate.length === 0) return false;

        const startDate = moment(state.startDate);
        const endDate = moment(state.endDate);

        if(startDate > endDate) return false;

        return true;
    }

    isDateRangeAvailable(startDate, endDate) {
        if(startDate === null || endDate === null) {
            return false;
        }
        if(startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")) {
            return this.isDayAvailable(startDate);
        }
        let dateCheck = startDate.clone();
        let available = true;
        while(dateCheck < endDate) {
            if(!this.isDayAvailable(dateCheck)) {
                available = false;
                break;
            }
            dateCheck = dateCheck.add(1, "day");
        }
        return available;
    }

    isDayAvailable(day) {
        if(!this.state.rentalContext.availableDates) {
            return false;
        }
        return !(day < moment()) && this.state.rentalContext.availableDates.findIndex((availableDate) => {
            return availableDate === day.format("YYYY-MM-DD");
        }) > -1;
    }

    getAmountOfDaysSelected() {
        const startDate = this.state.rentalContext.rentalInfo.startDate;
        const endDate = this.state.rentalContext.rentalInfo.endDate;
        if(startDate.length === 0 || endDate.length === 0) {
            return 0;
        }
        return Math.abs(moment(startDate).diff(moment(endDate), "days")) + 1;
    }

    render() {
        return (
            <RentalContext.Provider value={ this.state.rentalContext }>
                {this.props.children}
            </RentalContext.Provider>
        )
    }
}

export default RentalContext;
