import React from "react";

import Helmet from "../../components/Helmet";
import RentalInfoPageContent from "../../shopcrate-framework/page-content/rental/RentalInfoPageContent";

function RentalInfo() {
    return (
        <React.Fragment>
            <Helmet title="Gegevens"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Gegevens</h1>
                </div>
            </div>
            <div className="container">
                <RentalInfoPageContent/>
            </div>
        </React.Fragment>
    );
}

export default React.memo(RentalInfo);
