import React, {
    useContext
} from "react";
import RentalContext from "../context/internal/RentalManager";

function withRentalManager(ProvidedComponent) {
    return function Component(props) {
        const context = useContext(RentalContext);
        return (
            <ProvidedComponent
                { ...props }
                rentalContext={ context }
            />
        )
    }
}

export default withRentalManager;
