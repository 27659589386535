import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";

import Helmet from "../components/Helmet";
import Loading from "../shopcrate-framework/components/Loading";

function AlgemeneVoorwaarden() {
    const [markdown, setMarkdown] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get("/AlgemeneVoorwaarden.md")
            .then((response) => {
                setMarkdown(response.data);
            })
            .catch(() => {
                setError("Er ging iets fout bij het ophalen van de algemene voorwaarden. Probeer het later opnieuw.");
            })
    }, []);

    return (
        <React.Fragment>
            <Helmet
                title="Algemene voorwaarden"
            />
            <div className="jumbotron jumbotron-fluid jumbotron-header">
                <div className="container">
                    <h1>Algemene voorwaarden</h1>
                </div>
            </div>
            <div className="container mb-5">
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !markdown ? (
                    <Loading/>
                ) : (
                    <ReactMarkdown source={markdown}/>
                )}
            </div>
        </React.Fragment>
    );
}

export default React.memo(AlgemeneVoorwaarden);
