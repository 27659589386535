import React from "react";

import Helmet from "../../components/Helmet";
import RentalOrderOverviewPageContent from "../../shopcrate-framework/page-content/rental/RentalOrderOverviewPageContent";

function RentalOrderOverview() {
    return (
        <React.Fragment>
            <Helmet title="Overzicht"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Overzicht</h1>
                </div>
            </div>
            <div className="container">
                <RentalOrderOverviewPageContent/>
                <div className="card mb-3">
                    <div className="card-body">
                        <p className="card-text">
                            Door op "Afrekenen" te klikken, ga je akkoord met de <a href="/privacyverklaring" target="_blank" rel="noreferrer noopener">Privacyverklaring</a> en <a href="/algemene-voorwaarden" target="_blank" rel="noreferrer noopener">Algemene voorwaarden</a>.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(RentalOrderOverview);
