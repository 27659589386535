import React from "react";
import moment from "moment";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";
import Skeleton from "react-loading-skeleton";

import SteppedProgressBar from "../../components/progressbar/SteppedProgressBar";
import Loading from "../../components/Loading";
import withRentalManager from "../../hooks/withRentalManager";
import priceFormatter from "../../components/priceFormatter";
import RentalDateRangePicker from "../../components/RentalDateRangePicker";

class RentalDatePageContent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,

            minDate: moment(),
            startDate: null,
            endDate: null,
            focusedInput: "startDate"
        }
    }

    componentDidMount() {
        const rentalContext = this.props.rentalContext;
        const startDate = rentalContext.rentalInfo.startDate ? moment(rentalContext.rentalInfo.startDate) : null;
        const endDate = rentalContext.rentalInfo.endDate ? moment(rentalContext.rentalInfo.endDate) : null;
        this.setState({ startDate, endDate });
        rentalContext.setCurrentProduct(this.props.match.params.productId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.productId !== prevProps.match.params.productId) {
            this.props.rentalContext.setCurrentProduct(this.props.match.params.productId);
        }
    }

    setStartDate(startDate) {
        this.setState({ startDate });
        this.props.rentalContext.updateRentalInfo({
            startDate: startDate ? startDate.format("YYYY-MM-DD") : ""
        });
    }
    setEndDate(endDate) {
        this.setState({ endDate });
        this.props.rentalContext.updateRentalInfo({
            endDate: endDate ? endDate.format("YYYY-MM-DD") : ""
        });
    }

    onDatesChange = this.datesChange.bind(this);
    datesChange({ startDate, endDate }) {
        const available = this.props.rentalContext.isDateRangeAvailable(startDate, endDate);
        let modifiedEndDate = available ? endDate : this.getLastAvailableDate(startDate, endDate);
        const amountOfDays = Math.abs(startDate.diff(modifiedEndDate, "days")) + 1;
        if(amountOfDays >= 28) {
            modifiedEndDate = startDate.clone().add(28 - 1, "days");
        }
        this.setStartDate(startDate);
        this.setEndDate(modifiedEndDate);
    }

    getLastAvailableDate(startDate, endDate) {
        let dateCheck = startDate.clone();
        while(dateCheck < endDate) {
            if(!this.props.rentalContext.isDayAvailable(dateCheck)) {
                break;
            }
            dateCheck = dateCheck.add(1, "day");
        }
        return dateCheck.subtract(1, "day");
    }

    onDatePickerFocusChange = this.datePickerFocusChange.bind(this);
    datePickerFocusChange(focusedInput) {
        this.setState({ focusedInput: focusedInput === null ? "startDate" : focusedInput });
        if(focusedInput === "endDate") {
            this.setEndDate(null);
        }
    }

    isDayBlocked = this.dayBlocked.bind(this);
    dayBlocked(day) {
        if(!this.props.rentalContext.isDayAvailable(day)) {
            return true;
        }
        if(this.state.focusedInput === "endDate") {
            if(day < this.state.startDate) {
                return true;
            }
            // Block date if it's 28 days in the future.
            if(day > this.state.startDate.clone().add(27, "days")) {
                return true;
            }
            // Block date if between the startDate and the date currently being checked, lies a date that isn't available.
            if(!this.props.rentalContext.isDateRangeAvailable(this.state.startDate, day)) {
                return true;
            }
        }
        return false;
    }

    didSubmit = this.submit.bind(this);
    submit() {
        const rentalContext = this.props.rentalContext;
        if(!this.state.startDate || !this.state.endDate || this.state.startDate > this.state.endDate) {
            this.setState({ error: "Ongeldige start en/of einddatum ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(rentalContext.getAmountOfDaysSelected() > 28) {
            this.setState({ error: "Huur duur is langer dan 28 dagen. Neem contact op om langer dan 28 dagen te huren." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(!rentalContext.isDateRangeAvailable(this.state.startDate, this.state.endDate)) {
            this.setState({ error: "Het product is al verhuurd op 1 van de geselecteerde dagen." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        rentalContext.updateRentalInfo({
            startDate: this.state.startDate.format("YYYY-MM-DD"),
            endDate: this.state.endDate.format("YYYY-MM-DD")
        });
        this.props.history.push("/rental-order/" + rentalContext.product.id + "/info");
    }

    render() {
        const rentalContext = this.props.rentalContext;
        if(rentalContext.error) {
            if(rentalContext.errorCode === "DOESNT_EXIST" && this.props.page404) {
                return this.props.page404;
            }
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!rentalContext.product || !rentalContext.availableDates) {
            return (
                <Loading/>
            )
        }
        const productId = rentalContext.product.id;
        return (
            <React.Fragment>
                <div className="mt-2 mb-4">
                    <SteppedProgressBar>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="product"/> } to={ "/rental/" + productId } complete/>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="date"/> } to={ "/rental-order/" + productId + "/date" } active/>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="information"/> } to={ "/rental-order/" + productId + "/info" }/>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="paymentMethod"/> } to={ "/rental-order/" + productId + "/paymentmethod" }/>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="overview"/> } to={ "/rental-order/" + productId + "/overview" }/>
                        <SteppedProgressBar.Step title={ <Trans i18nKey="payment"/> }/>
                    </SteppedProgressBar>
                </div>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { rentalContext.priceError && (
                    <Alert variant="danger">{ rentalContext.priceError }</Alert>
                )}

                <div className="d-flex justify-content-center">
                    <RentalDateRangePicker
                        startDate={ this.state.startDate }
                        endDate={ this.state.endDate }
                        onDatesChange={ this.onDatesChange }
                        focusedInput={ this.state.focusedInput }
                        onFocusChange={ this.onDatePickerFocusChange }
                        initialVisibleMonth={ () => moment() }
                        minimumNights={ 0 }
                        isDayBlocked={ this.isDayBlocked }
                    />
                </div>

                <div className="card mb-3 mt-3">
                    <div className="card-body">
                        { (!this.state.startDate || !this.state.endDate) ? (
                            <p className="card-text">
                                <Trans i18nKey="rentalSelectDates"/>
                            </p>
                        ) : rentalContext.price === null ? (
                            <React.Fragment>
                                <p className="card-text">
                                    <Skeleton/>
                                </p>
                                <p className="card-text">
                                    <Skeleton/>
                                </p>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <p className="card-text">
                                    { rentalContext.getAmountOfDaysSelected() === 1 ? (
                                        <React.Fragment>
                                            { i18next.t("rentalDateSelected").replace("{date}", this.state.startDate.format("dddd LL")) }
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            { i18next.t("rentalDatesSelected")
                                                .replace("{startDate}", this.state.startDate.format("dddd LL"))
                                                .replace("{endDate}", this.state.endDate.format("dddd LL")) }
                                        </React.Fragment>
                                    )}
                                </p>
                                <p className="card-text">
                                    <b>
                                        { rentalContext.getAmountOfDaysSelected() }{" "}
                                        { i18next.t(rentalContext.getAmountOfDaysSelected() === 1 ? "day" : "days").toLowerCase() }:{" "}
                                        { priceFormatter(rentalContext.price) }
                                    </b>
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                </div>

                <div className="card mb-3 mt-3">
                    <div className="card-body">
                        <p className="card-text">
                            <Trans i18nKey="rentalContact28Days"/>
                        </p>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body">
                        <div className="float-right">
                            <Button onClick={ this.didSubmit } variant="success">
                                <Trans i18nKey="nextStep"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withRentalManager(RentalDatePageContent));
