import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Col,
    Container,
    Jumbotron,
    Row
} from "react-bootstrap";

function Footer() {
    const firstYear = 2021;
    const currentYear = new Date().getFullYear();
    let yearString =  firstYear + " - " + currentYear;
    if(firstYear === currentYear) {
        yearString = String(currentYear);
    }

    return (
        <React.Fragment>
            <Jumbotron fluid className="mb-0 text-white" style={{ backgroundColor: "#001B4B" }}>
                <Container>
                    <Row>
                        <Col md="3" className="my-3">
                            <h3>Voertuigen</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/rental/8" className="nav-link active">6-personen materiaalbus</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="3" className="my-3">
                            <h3>Webshop</h3>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <Link to="/privacyverklaring" className="nav-link active">Privacyverklaring</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/algemene-voorwaarden" className="nav-link active">Algemene voorwaarden</Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="3" className="my-3">
                            <h3>Contact</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: 0
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-envelope"/></span>
                                    <a href="mailto:info@smithtechniek.nl">info@smithtechniek.nl</a>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-phone"/></span>
                                    <a href="dial:+31620967847">06 209 678 47</a>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-primary"><i className="fas fa-phone"/></span>
                                    <a href="dial:+31653376147">06 533 761 47</a>
                                </li>
                            </ul>
                        </Col>
                        <Col md="3" className="my-3">
                            <h3>Info</h3>
                            <ul className="mt-2 fa-ul" style={ {
                                paddingLeft: "24px",
                                marginLeft: "8px"
                            } }>
                                <li className="mt-3">
                                    <span className="fa-li text-white">IBAN</span>
                                    <span className="text-primary ml-3">
                                        NL35 RABO 0366 7950 31
                                    </span>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-white">KVK</span>
                                    <span className="text-primary ml-3">
                                        24388553
                                    </span>
                                </li>
                                <li className="mt-3">
                                    <span className="fa-li text-white">BTW</span>
                                    <span className="text-primary ml-3">
                                        NL815251129B01
                                    </span>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="mb-0 py-4" style={{ backgroundColor: "#001438" }}>
                <Container className="text-white">
                    <p className="my-2 text-center text-md-left text-white">
                        <span className="d-none d-sm-block float-right">A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.</span>
                        Copyright &copy; { yearString } B&S Logistiek.{" "}
                        <span className="d-none d-sm-inline-block">
                            All rights reserved.
                        </span>
                        <span className="d-block d-md-none mt-2">
                            A website by <a href="https://srproductions.nl" target="_blank" rel="noopener noreferrer">SR Productions</a>.
                        </span>
                    </p>
                </Container>
            </Jumbotron>
        </React.Fragment>
    )
}

export default React.memo(Footer);
