import React from "react";

import Helmet from "../../components/Helmet";
import RentalDatePageContent from "../../shopcrate-framework/page-content/rental/RentalDatePageContent";
import Page404 from "../Page404";

function RentalDate() {
    return (
        <React.Fragment>
            <Helmet title="Datum selectie"/>
            <div className="jumbotron jumbotron-fluid jumbotron-header jumbotron-sm">
                <div className="container">
                    <h1>Datum selectie</h1>
                </div>
            </div>
            <div className="container">
                <RentalDatePageContent
                    page404={ <Page404/> }
                />
            </div>
        </React.Fragment>
    );
}

export default React.memo(RentalDate);
