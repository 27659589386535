import React from "react";
import {
    Alert,
    Carousel
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ProductCollectionBase from "./ProductCollectionBase";
import ProductCard from "../product/ProductCard";

class ProductCollectionCarousel extends ProductCollectionBase {
    getCarouselSlideContent(product) {
        return (
            <div className="d-flex justify-content-center align-content-center rounded p-5" style={{ backgroundColor: "white" }}>
                <ProductCard product={ product } size="large"/>
            </div>
        )
    }

    render() {
        if(this.state.error !== null) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(this.state.collection === null) {
            return (
                <div className="mb-3">
                    <Skeleton height={ 125 }/>
                    <div className="mb-1">
                        <Skeleton height={ 19 } width={ 100 }/>
                    </div>
                    <Skeleton height={ 19 } width={ 60 }/>
                    <Skeleton height={ 19 }/>
                </div>
            )
        }
        if(this.state.collection.products.length === 1) {
            return this.getCarouselSlideContent(this.state.collection.products[0]);
        }
        return (
            <Carousel
                className="rounded-top"
                prevIcon={
                    <i className="fas fa-angle-left text-primary" style={{ fontSize: "2.5rem" }}/>
                }
                nextIcon={
                    <i className="fas fa-angle-right text-primary" style={{ fontSize: "2.5rem" }}/>
                }
            >
                { this.state.collection.products.map((product, index) => (
                    <Carousel.Item key={ index }>
                        { this.getCarouselSlideContent(product) }
                    </Carousel.Item>
                ))}
            </Carousel>
        )
    }
}

export default ProductCollectionCarousel;
